import React, {useEffect ,useState} from "react";

import {  toast,ToastContainer } from 'react-toastify';

//material
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import partnerAPI from "../../../Services/partnerAPI";

export default function ControleDeFidelidade (){  
    const [asset , setAsset] = useState()  
    const [ativo , setAtivo] = useState(false)
    const [configCashBack , setConfigCashBack] = useState({
        cdConfigCashBack: 0, //NAO
        nmStore: "",
        cdAsset: 0,
        dayToSendEmail: "",
        emailClient: "" 

    });

    useEffect(()=>{
        contratoInteligente();
        loadConfigurationCashback();
    },[] );

    const contratoInteligente = async () =>{
        const contract = sessionStorage.getItem('contract');
        const uid = sessionStorage.getItem('uid');
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };
        await partnerAPI.get(`Partner/DadosToken_GetData?contract=${contract}&uid=${uid}`,{

        },config).then(
            result =>{
                const dataReturn = JSON.parse(result.data.data)
                setAsset(dataReturn.CdAsset)

            }
        ).catch(error =>{
            console.log(error.response, 'deu erro')
        })

    }
    
    const loadConfigurationCashback = async () =>{
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };
        await partnerAPI.post("/Partner/CashBack/GetConfiguration",{
            cryptoUserID : window.sessionStorage.getItem("uid")
        },config).then(
            result =>{

                const jsonResult = JSON.parse(result.data.content);
                console.log(jsonResult.CdConfigCashBack,"result GetConfiguration")
                setConfigCashBack({
                    cdConfigCashBack: jsonResult.CdConfigCashBack, //NAO
                    nmStore: jsonResult.NmStore,
                    cdAsset: jsonResult.CdAsset,
                    dayToSendEmail: jsonResult.DayToSendEmail,
                    emailClient: jsonResult.EmailClient
            
                })

                setAtivo(jsonResult.IsActive)

                console.log(configCashBack,"configCashBack")
            }
        ).catch(error =>{
            console.log(error.response, 'deu erro')
        })
    }

    const handleAlterar = (e) =>{
        const { name, value } = e.target;
            setConfigCashBack({
            ...configCashBack,
            [name]: value,
        });
        
    }

    const handleAtivo = (e) =>{
        setAtivo(e.target.checked)
    }

    const  handleEnviar= async (e) => {
        e.preventDefault();
        
        const jsonData ={        
            cdConfigCashBack: 0, //NAO
            nmStore: configCashBack.nmStore,
            cdContract: 0,
            cdAsset: asset,
            dayToSendEmail: configCashBack.dayToSendEmail,
            isActive: ativo,
            emailClient: ""    
        }
        console.log(jsonData)

        const json = JSON.stringify(jsonData)
        console.log(json)

        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };
        
        const cashBackConfig = await partnerAPI.post("/Partner/CashBack/AddConfiguration",json,config) 
        console.log(cashBackConfig , "Cash Back Add Config");
        
        if(cashBackConfig){
            toast.success('Configuirações Cadastrada com sucesso');
            loadConfigurationCashback();
        };
        
            
    };

    return(
        <div className=" ms-4 pb-5 pt-5">
            <div>
                <h5>Controle de Fidelidade</h5>
            </div> 
            <div className=" col-sm-12">
                <Card className=" text-center col-sm-4 mb-5 mt-3">
                    <CardContent className="col-sm-12 ">
                        <div>
                            <div>
                                <TextField
                                    style={{paddingTop:10}}
                                    className="col-sm-12"
                                    id="standard-textarea"
                                    label="Nome da Loja"
                                    placeholder="Nome da Loja"
                                    variant="standard"
                                    type="text"   
                                    value={configCashBack.nmStore}
                                    onChange={handleAlterar}
                                    name="nmStore"
                                />    
                            </div>

                            <div className="mt-4">
                            <FormControl fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">
                                Disparo de Email
                            </InputLabel>
                            <Select
                                className="col-sm-12 align-text-left"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"                    
                                label="DisparoDeEmail"
                                value={configCashBack.dayToSendEmail}
                                onChange={handleAlterar}                      
                                name="dayToSendEmail"
                            >
                                <MenuItem value={1}>Disparo diário</MenuItem>
                                <MenuItem value={15}>Disparo em 15 dias</MenuItem>
                                <MenuItem value={30}>Disparo em 30 dias</MenuItem>
                            </Select>
                            </FormControl>
                            </div>
                            <div style={{ paddingTop:20}}>
                                <FormGroup >
                                    <FormControlLabel  control={<Checkbox  checked={ativo} onChange={handleAtivo}/>} label="Ativo" color="secondary" />
                                    
                                </FormGroup>
                            </div>
                            <div className="mt-4">
                                <Stack direction="row-reverse">
                                    <Button
                                        style={{width:100}}
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleEnviar}
                                    >
                                        <b>Enviar</b>
                                    </Button>
                                </Stack>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <ToastContainer />
        </div>
    ) 
    
}