import React , {useState, useContext, useEffect} from 'react';
import partnerAPI from '../../../../Services/partnerAPI';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import CardActionArea from '@mui/material/CardActionArea';

//components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function VerificacaoConta() {
  useEffect(() => {
    verificarConta();
    marginbottomdynamic();
    bancoList();
    contaList();
    ChavePixList();
  }, []);

  const[agencia, setAgencia ]= useState('');
  const[numeroConta, setNumeroConta ]= useState('');
  const[digito, setDigito ]= useState('');
  const[chavePixValue, setChavePixValue ]= useState('');

  const[rg, setRg ]= useState('');
  const[cpf, setCpf ]= useState('');
  const[email, setEmail ]= useState('');
  const[nomeCompleto, setNomeCompleto ]= useState('');
  const[texEndereco, setTexEndereco ]= useState('');


  const [conta, setConta] = useState('');
  const [banco, setBanco] = useState('');
  const [chavePix, setChavePix] = useState('');

  const[perfilVerificado, setPerfilVerificado ]= useState(false);
  const [dynamicMarginBottom, setDynamicMarginBottom] = useState(360);
  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(270);

  const handleChangeConta = (event) => {
    setConta(event.target.value);
  };
  const handleChangeBanco = (event) => {
    setBanco(event.target.value);
  };
  const handleChangeChavePix = (event) => {
    setChavePix(event.target.value);
  };

  const marginbottomdynamic = () =>{

    if(window.screen.width === 1920 && window.screen.height === 1080){
        setDynamicMarginBottom(110);
    }else{
        setDynamicMarginBottom(0);
    }

  }

  const [accordionCheckTeste] = useState({
    accordionDefaultExpanded: [],
    select: false
  });

  const [dadosPessoais, setDadosPessoais] = useState({
    nome: "",
    CPF: "",
    RG: "",
    Email: ""
  });

  const [dadosBancarios, setDadosBancarios] = useState({
      agencia: "",
      conta: "",
      digito: "",
      Banco: "",
      TipoChavePix: "",
      ChavePix: ""
  });

  const [listConta] = useState({
    data:[]
  });

  const [listBanco] = useState({
      data:[]
  });

  const [listChavePix] = useState({
      data:[]
  });

  const testeButton = () =>{
    toast.success('Success Notification !', {
      position: 'top-right'
    });

    toast.dark('Success Notification !', {
      position: 'top-right'
    });

    toast.warning('Success Notification !', {
      position: 'top-right'
    });

    toast.warn('Success Notification !', {
      position: 'top-right'
    });

    toast.info('Success Notification !', {
      position: 'top-right'
    });

  }

  const localizarBanco = (nmRede) =>{
          
      switch (nmRede) {
        case 'BANCO DO BRASIL S.A (BB)':
        return 1;
        case 'BRADESCO S.A.':
        return 237;
        case 'NU PAGAMENTOS S.A (NUBANK)':
        return 260;
        case 'NEXT BANK (MESMO CÓDIGO DO BRADESCO)':
        return 237;
        case 'BANCO INTER S.A.':
        return 77;
        case 'ITAÚ UNIBANCO S.A.':
        return 341;
        case 'CAIXA ECONÔMICA FEDERAL (CEF)':
        return 104;
        case 'BANCO ORIGINAL S.A.':
        return 212;
        case 'BANCO C6 S.A – C6 BANK':
        return 336;
        default:
        return "";
      }
  }

  const localizarConta = (tipoSaque) =>{
    switch (tipoSaque) {
      case 'Conta Corrente':
      return 1;
      case 'Conta Poupanca':
      return 2;
      default:
      return "";
    }
  }

  const localizarChavePix = (eliasCrypto) =>{
      
    switch (eliasCrypto) {
      case 'CPF/CNPJ':
        return 1;
      case 'CELULAR':
        return 2;
      case 'E-MAIL':
        return 3;
      case 'CHAVE ALEATÓRIA':
        return 4;
        default:
            return "";
    }
  }

  const checkOpenAccordionNumber = (num) => {
        
    //alert(JSON.stringify(accordionCheckTeste), 'inicio');

    if(num === 1){
        //alert(num, 'accordion');
        const existeAcordionSelecionado = accordionCheckTeste.accordionDefaultExpanded.some(x=> x.numberAccordion === 1 && x.open === true);

        if(existeAcordionSelecionado){
            console.log(existeAcordionSelecionado, 'existeAcordionSelecionado');
            console.log('selecionado accordion 1 limpando')

            const indexDelete =  accordionCheckTeste.accordionDefaultExpanded.findIndex(x=> x.numberAccordion === 1 && x.open === true);
            console.log(indexDelete, 'indexDelete accordion 1')
            accordionCheckTeste.accordionDefaultExpanded.splice(indexDelete, 1);
            
            console.log(accordionCheckTeste.accordionDefaultExpanded, 'array 1');
        }else{
            const object = {
                numberAccordion: num, 
                open: true
            }
            accordionCheckTeste.accordionDefaultExpanded.push(object);
            console.log('selecionado accordion 1')
            setDynamicPaddingBottom(100);
        }

    }

    if(num === 2){
        //alert(num, 'accordion');
        const existeAcordionSelecionado = accordionCheckTeste.accordionDefaultExpanded.some(x=> x.numberAccordion === 2 && x.open === true);

        if(existeAcordionSelecionado){
            console.log(existeAcordionSelecionado, 'existeAcordionSelecionado');
            console.log('selecionado accordion 2 limpando')

           
            const indexDelete =  accordionCheckTeste.accordionDefaultExpanded.findIndex(x=> x.numberAccordion === 2 && x.open === true);
            console.log(indexDelete, 'indexDelete accordion 2')
            accordionCheckTeste.accordionDefaultExpanded.splice(indexDelete, 1);
            
            //console.log(accordionCheckTeste.accordionDefaultExpanded, 'array 2');
            setDynamicPaddingBottom(100);
        }else{
            const object = {
                numberAccordion: num, 
                open: true
            }
            accordionCheckTeste.accordionDefaultExpanded.push(object);
            console.log('selecionado accordion 2')
            setDynamicPaddingBottom(100);
        }
    }
   // alert(JSON.stringify(accordionCheckTeste), 'final');
   marginbottomdynamic();
  }

  const upLoadImages = () => {
    let input = document.createElement('input');
    input.type = 'file';

    input.onchange = _ => {
      const fileObject = Array.from(input.files);

      if(fileObject[0].type === "image/png" || fileObject[0].type === "image/jpeg"){ 
        convertImageToBase64(fileObject);
        return;
      }

      toast.warning('Arquivo não é uma imagem !', {
        position: 'top-right'
      });

    };

    input.click();
  }

  const convertImageToBase64 = (formObject)=> {
    console.log(formObject, 'formObject')
    Promise.all(
      formObject.map(
        (image) =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (file) => {
              resolve(file.target.result.split(',')[1]);
            };
  
            fileReader.onerror = (error) => reject(error);
  
            fileReader.readAsDataURL(image);
          })
      )
    ).then((base64Images) => {
      console.log(base64Images, 'base64Images')
      toast.success('Arquivo enviado com Sucesso !', {
        position: 'top-right'
      });  
      // Send base64Images to server
    });
  }
  
  const verificarConta = async () => {
    console.log(sessionStorage.getItem("uid"), 'sessionStorage.getItem("uid")')
    const imagemRGCPF = await ValidacaoKYC(sessionStorage.getItem("uid"),1);
    const imagemCorrespondencia = await ValidacaoKYC(sessionStorage.getItem("uid"),2);

    const validacaoKycObject ={
      existeDadosBancariosEdadosPessoais: false,
      valicacaoBackOfficeImagemRGCPF: false,
      ValidacaoBackOfficeImagemCorrespondencia: false
    }

    if(imagemRGCPF.dadosBancarios && imagemRGCPF.dadosPessoais){
      validacaoKycObject.existeDadosBancariosEdadosPessoais = true;
    }

    if(imagemCorrespondencia.imagemInfo && imagemCorrespondencia.dadosPessoais){
      validacaoKycObject.existeDadosBancariosEdadosPessoais = true;
    }

    if(imagemCorrespondencia.imagemInfo && imagemCorrespondencia.dadosPessoais){
      validacaoKycObject.existeDadosBancariosEdadosPessoais = true;
    }

    if(imagemCorrespondencia.retorno === "Conta Verificada" && imagemCorrespondencia.retorno === "Conta Verificada"){
      //precisa melhorar a verificação
      validacaoKycObject.valicacaoBackOfficeImagemRGCPF = true;
      validacaoKycObject.ValidacaoBackOfficeImagemCorrespondencia = true;
      setPerfilVerificado(true);
      setDynamicPaddingBottom(50);
    }

    //console.log(imagemRGCPF, 'imagemRGCPF');
    //console.log(imagemCorrespondencia, 'imagemCorrespondencia');
  }

  const ValidacaoKYC = async (cdPessoa, tipoImagem) =>{
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
      },
    };

    const body = {
        cdPessoa: cdPessoa,
        tipoImagem: tipoImagem,

    };

    return partnerAPI.post('/Partner/VerificationAccount',body ,config).then(
      result => {
          console.log(result, 'verificacaoKYC');
          return result.data;
      }
    ).catch(
      error => {
        // console.log(error.response, 'catch')
        if (error.response.status === 401) {
            
        }
    });
  }

  const bancoList = async()  =>{               
 
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")
      },

    };

    partnerAPI.post('/Partner/LocateBank',{},config).then(
      result => {
        console.log(result, 'bancoList');
        listBanco.data = result.data;
       
      }
    ).catch(error =>{
      console.log(error.response);
      if (error.response.status === 401) {

      }
    })
}

const contaList = async()  =>{              

    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")},

    };

    partnerAPI.post('/Partner/TypeAccount',{},config).then(
      result => {
        console.log(result, 'contaList');
        listConta.data = result.data;
        // console.log(listConta,'listConta')

      }
    ).catch(error =>{
      console.log(error.response);
      if (error.response.status === 401) {

      }
    })
}

const ChavePixList = async()  =>{
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")},

    };

    partnerAPI.post('/Partner/TypePix',{},config).then(
      result => {
        console.log(result, 'ChavePixList');
        listChavePix.data = result.data
      }
    ).catch(error =>{
      console.log(error.response);
      if (error.response.status === 401) {

      }
    })
}


  const cadastrarBanco = async (numeroAgencia, numeroConta, digitoConta, numeroBanco,tipoChavePixValue, chavePix, tipoConta) =>{
    // console.log(tokenHeader, 'tokenHeader')
    
    const config = {
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token")},
    };

    if(numeroAgencia === "" || numeroConta === "" || digitoConta === ""){

    }

    if(tipoChavePixValue === "" || chavePix === "" || tipoConta === "" || numeroBanco === ""){

    }

    const bodyRequest = {
      cdPessoa: sessionStorage.getItem("uid"),
      nrConta: numeroConta,
      nrContaDigito: digitoConta,
      nrAgencia: numeroAgencia,
      nrDigitoAgencia: "0",
      nrBanco: localizarBanco(numeroBanco),
      nrTipoChavePix: localizarChavePix(tipoChavePixValue),
      dsChavePix: chavePix,
      nrTipoConta: localizarConta(tipoConta)        
    }
    // console.log(bodyRequest, 'bodyRequest cadastrarBanco');
    

    partnerAPI.post('/Partner/BankSign', bodyRequest,config).then(
    result => {
      // console.log(result);
    }
    ).catch(error =>{
      // console.log(error.response);
      if (error.response.status === 401) {

      }})
  }
    
  return (
    <div>
        <div style={{padding: 10, paddingBottom: dynamicPaddingBottom}}>

          <h4 className='my-3' style={{paddingLeft: 15}}>Verificação de Conta</h4>

          {!perfilVerificado &&
            <div className='col-sm-5' style={{marginBottom: 10}}>
              <Accordion onClick={()=> checkOpenAccordionNumber(1)} sx={{padding: 1}}>

                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                  >
                  <Typography>Dados Pessoais</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='container col-sm-12'>                
                      <TextField className='col-sm-12' id="outlined-basic" value={nomeCompleto} onChange={e => setNomeCompleto(e.target.value)} label="Nome Completo" variant="standard"color='secondary' />
                      <TextField className='col-sm-12' id="outlined-basic" value={cpf} onChange={e => setCpf(e.target.value)} label="CPF" variant="standard"color='secondary' />
                      <TextField className='col-sm-12' id="outlined-basic" value={rg} onChange={e => setRg(e.target.value)} label="RG" variant="standard"color='secondary' />
                      <TextField className='col-sm-12' id="outlined-basic" value={email} onChange={e => setEmail(e.target.value)} label="Email" variant="standard" color='secondary' />
                      
                      <div className='row'>
                        <div className='col-sm-9'></div>
                        <div className='col-sm-3'>
                          <div style={{padding: 10}}>
                            <Button 
                              color='secondary'
                              variant="contained" 
                              endIcon={<ContentCopyIcon />} 
                              onClick={()=> testeButton()}>
                                Salvar
                              </Button>
                          </div>
                        </div>
                      </div>

                    </div>

                  </AccordionDetails>

              </Accordion>
            </div>
          }

          <div className='col-sm-5' style={{marginBottom: 10}}>



            <Accordion onClick={()=> checkOpenAccordionNumber(2)} sx={{padding: 1}}>
              <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
              >
                  <Typography>Dados Bancários</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className='container col-sm-12'>                
                  <TextField className='col-sm-12' id="outlined-basic" value={agencia} onChange={e => setAgencia(e.target.value)} label="Agencia" variant="standard" color='secondary' />
                  <div className='row'>
                    <div className='col-sm-6'>
                      <TextField className='col-sm-12' id="outlined-basic" value={numeroConta} onChange={e => setNumeroConta(e.target.value)} label="Numero da Conta" variant="standard" color='secondary' />
                    </div>
                    <div className='col-sm-6'>
                      <TextField className='col-sm-12' id="outlined-basic" value={digito} onChange={e => setDigito(e.target.value)} label="Digito" variant="standard" color='secondary' />
                    </div>
                  </div>
                  
                  <FormControl variant="standard" className='col-sm-12'>
                    <InputLabel id="demo-simple-select-standard-label">Conta</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={conta}
                      onChange={handleChangeConta}
                      label="conta"
                      color='secondary'
                    >
                      {
                        listConta.data.map((conta) => (
                          <MenuItem value={conta.nrTipoConta}>{conta.nmTipoConta}</MenuItem>
                        ))
                      }

                    </Select>
                  </FormControl>

                  <FormControl variant="standard" className='col-sm-12'>
                    <InputLabel id="demo-simple-select-standard-label">Banco</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={banco}
                      onChange={handleChangeBanco}
                      label="banco"
                      color='secondary'
                    >
                      {
                        listBanco.data.map((banco) => (
                          <MenuItem value={banco.nrBanco}>{banco.nmBanco}</MenuItem>
                        ))
                      }

                    </Select>
                  </FormControl>

                  <FormControl variant="standard" className='col-sm-12'>
                    <InputLabel id="demo-simple-select-standard-label">Tipo Chave Pix</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={chavePix}
                      onChange={handleChangeChavePix}
                      label="chavePix"
                      color='secondary'
                    >

                    {
                      listChavePix.data.map((chave) => (
                        <MenuItem value={chave.nrTipoChavePix}>{chave.nmTipoChavePix}</MenuItem>
                      ))
                    }

                    </Select>
                  </FormControl>
                  <TextField className='col-sm-12' id="outlined-basic" value={chavePix} onChange={e => setChavePix(e.target.value)} label="Chave Pix" variant="standard" color='secondary' />
                
                </div>

                <div className='row'>
                  <div className='col-sm-9'></div>
                  <div className='col-sm-3'>
                    <div style={{padding: 10}}>
                      <Button variant="contained" color='secondary' endIcon={<ContentCopyIcon />}>Salvar</Button>
                    </div>
                  </div>
                </div>


              </AccordionDetails>
            </Accordion>
          </div>

          {!perfilVerificado &&
            <div>

              <div className='col-sm-5' style={{marginBottom: 10}}>
                <CardActionArea>                      
                  <Card sx={{paddingTop: 2 ,paddingBottom: 2}} onClick={()=> upLoadImages()}>
                      <div style={{paddingLeft: 10}}>
                        <div className='row'>
                          <div className='col-sm-10'>Envio RG / CPF/ CNH</div>
                          <div className='col-sm-2'><DriveFolderUploadIcon/></div>
                        </div>
                      </div>
                  </Card>
                </CardActionArea>
              </div>

              <div className='col-sm-5'>
                <CardActionArea onClick={()=> upLoadImages()}>
                  <Card sx={{paddingTop: 2 ,paddingBottom: 2}}>
                      <div style={{paddingLeft: 10}}>
                        <div className='row'>
                          <div className='col-sm-10'>Envio Endereço de Correspondência</div>
                          <div className='col-sm-2'><DriveFolderUploadIcon/></div>
                        </div>
                      </div>
                  </Card>
                </CardActionArea>
              </div>

              <ToastContainer />
            </div>
          }

        </div>
        
        <div className='container'>
          <div className='row' style={{marginBottom: dynamicMarginBottom}}>
            {perfilVerificado &&
              <h4 style={{color: 'green', marginBottom: 50}}>Perfil Verficado !</h4>
            }
          </div>
        </div>



    </div>
  );
}