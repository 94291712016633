import React, { useState, useEffect, useContext } from "react";
import Token from "./create/token";
import authContext from "../../Context/authContext";
import firstLoginWhiteLabelContext from "../../Context/firstLoginWhiteLabelContext";
import defaultAccessContext from "../../Context/defaultAccessContext";
import partnerAPI from "../../Services/partnerAPI";
import { useNavigate } from "react-router-dom";
import WalletList from "./wallet/walletList";
import axios from "axios";

import KycOnBoarding from "./onBoarding/KycOnBoarding";

//material
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Divider from "@mui/material/Divider";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from "@mui/icons-material/Add";
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import PixIcon from '@mui/icons-material/Pix';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

//component
import CircularProgress from "@mui/material/CircularProgress";
import { Wallet } from "@mui/icons-material";
import { List } from "@mui/material";
import { useDrawerContext } from "../../Context/drawerContext";

export default function Home(props) {
  useEffect(() => {
    //marginbottomdynamic();
    localizarSaldoCrypto();    
    carregarPlanos();
    loadConfigInternetBlocking();
  }, []);
//
  const navigate = useNavigate();
  const [value, setValue] = useState("one");
  const [dynamicPaddingBottom, setDynamicPaddingBottom] = useState(270);
  const [valorDisponivelCripto, setValorDisponivelCripto] = useState("");
  const [loading, setLoading] = useState(false);
  const [saldoPatrimonioSelect, setSaldoPatrimonioSelect] = useState(false);
  const [configAreaPix, setConfigAreaPix] = useState(true);
  const [configSwap, setConfigSwap] = useState(true);

  const { authenticated, setAuthenticated } = useContext(authContext);
  const { isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel } = useContext(
    firstLoginWhiteLabelContext
  );
  const { authenticatedDefaultAccess, setAuthenticatedDefaultAccess } =
    useContext(defaultAccessContext);

  const [accordionCheckTeste] = useState({
    accordionDefaultExpanded: [],
    select: false,
  });
  const [mostrarBtnCashBack, setMostrarBtnCashBack] = useState(false); 
  const [cash , setCash] = useState("")

  const [visibility, setVisibility] = useState(false);

   const { drawerOpen, setDrawerOpen, drawerWidth, setDrawerWidth,configProducts ,setConfigProducts } = useDrawerContext();

  //   const handleChange = (event: React.SyntheticEvent, newValue) => {
  //     setValue(newValue);
  //   };

  const marginbottomdynamic = async () => {
    console.log("entrou marginbottomdynamic");
    if (window.screen.width !== 1920 && window.screen.height !== 1080) {
      setDynamicPaddingBottom(65);
      return;
    }
  };

  const handleVisibility = () => {
    setVisibility(!visibility);
  };

  const checkOpenAccordionNumber = (num) => {
    //alert(JSON.stringify(accordionCheckTeste), 'inicio');

    if (num === 1) {
      //alert(num, 'accordion');
      const existeAcordionSelecionado =
        accordionCheckTeste.accordionDefaultExpanded.some(
          (x) => x.numberAccordion === 1 && x.open === true
        );

      if (existeAcordionSelecionado) {
        console.log(existeAcordionSelecionado, "existeAcordionSelecionado");
        console.log("selecionado accordion 1 limpando");

        const indexDelete =
          accordionCheckTeste.accordionDefaultExpanded.findIndex(
            (x) => x.numberAccordion === 1 && x.open === true
          );
        console.log(indexDelete, "indexDelete accordion 1");
        accordionCheckTeste.accordionDefaultExpanded.splice(indexDelete, 1);

        console.log(accordionCheckTeste.accordionDefaultExpanded, "array 1");
      } else {
        const object = {
          numberAccordion: num,
          open: true,
        };
        accordionCheckTeste.accordionDefaultExpanded.push(object);
        console.log("selecionado accordion 1");
        setDynamicPaddingBottom(399);
        return;
      }
    }

    if (num === 2) {
      //alert(num, 'accordion');
      const existeAcordionSelecionado =
        accordionCheckTeste.accordionDefaultExpanded.some(
          (x) => x.numberAccordion === 2 && x.open === true,
              setDrawerWidth(56),
              setDrawerOpen(false) 
        );

      if (existeAcordionSelecionado) {
        console.log(existeAcordionSelecionado, "existeAcordionSelecionado");
        console.log("selecionado accordion 2 limpando");

        const indexDelete =
          accordionCheckTeste.accordionDefaultExpanded.findIndex(
            (x) => x.numberAccordion === 2 && x.open === true
          );
        console.log(indexDelete, "indexDelete accordion 2");
        accordionCheckTeste.accordionDefaultExpanded.splice(indexDelete, 1);

        console.log(accordionCheckTeste.accordionDefaultExpanded, "array 2");
        setDynamicPaddingBottom(300);
        return;
      } else {
        const object = {
          numberAccordion: num,
          open: true,
        };
        accordionCheckTeste.accordionDefaultExpanded.push(object);
        console.log("selecionado accordion 2");
        setDynamicPaddingBottom(80);
        return;
      }
    }
    // alert(JSON.stringify(accordionCheckTeste), 'final');
    marginbottomdynamic();
  };

  const localizarSaldoCrypto = () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    };

    partnerAPI
    .post(
      "/Partner/EquityBalance",
      {
        cryptoUserID: sessionStorage.getItem("uid"),
      },
      config
    )
    .then((result) => {
      setValorDisponivelCripto(
        result.data.valorPatrimonio.replace("¤", "R$ ")
      );
      setSaldoPatrimonioSelect(true);
      console.log(result.data, "localizarSaldoCrypto");
    })
    .catch((error) => {
      console.log(error.response, "localizarSaldoCrypto");
      if (error.response.status === 401) {
        setAuthenticated(false);
      }
    });
  };

  const carregarPlanos = async () => {
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };  
    
    const plano  = await partnerAPI.post("/Partner/Plan",{
      cryptoUserID: window.sessionStorage.getItem("uid")        
    },config).then(
    result => {
      return result.data.content  
    }).catch(error=>{
      console.log(error.response, "Partner Plan Error")
    });
    console.log(plano, "Plano")
        
    if(plano.nmPlan === "Free Tier" ){
      if(plano.nmProduct ===  "Cashback"){
        setMostrarBtnCashBack(true)
        console.log(cash)
      }
    } 
  }

    const handleDrawerOpen = () => {
    setDrawerOpen(false);
    setDrawerWidth(56);
     
  };

  const loadConfigInternetBlocking = async () => {
    const config = {
      headers: {
        Authorization: "Bearer " + (await sessionStorage.getItem("token")),
      },
    };

    partnerAPI.post("/Partner/Config" ,
      {
        cryptoUserID: sessionStorage.getItem("uid"),
      }, config).then(result=>{
        //console.log(configProducts,"teste da config")
        setConfigProducts(result.data)
        setConfigSwap(result.data.actionSwap)
        setConfigAreaPix(result.data.actionAreaPix)

      })
      .catch(error => {
        console.error('Erro', error);
  });

  };


  return (
    <>

      <div className="container-fluid ">
        <firstLoginWhiteLabelContext.Provider
          value={{ isfirstLoginWhiteLabel, setIsFirstLoginWhiteLabel }}
        >
          {isfirstLoginWhiteLabel && <Token />}

          {!isfirstLoginWhiteLabel && (
            <div style={{ marginTop: 20, marginBottom: 100 }} >
              <div className=" ">
                <div className="row  ">
                  <div className="col-sm-4  ">
                    <div className="col-sm-12 ">
                      <Accordion
                        onClick={() => checkOpenAccordionNumber(2)}
                        style={{ marginBottom: 50 }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>Wallet</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div style={{ marginBottom: 14 }}>
                            <div style={{ margin: 8 }}>
                              <Typography>Saldo em Criptomoedas</Typography>
                            </div>
                          </div>

                          <WalletList />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  <div className="col-sm-8">
                    <div className="text-right">
                      <div className="col-sm-4">
                        <div style={{ padding: 20, height: "100px" }}>
                          <div style={{ display: "flex" }}>
                            <b>Saldo Patrimônio</b>
                            <div style={{ padding: "0 10px" }}>
                              {visibility ? (
                                <VisibilityIcon onClick={handleVisibility} />
                              ) : (
                                <VisibilityOffIcon onClick={handleVisibility} />
                              )}
                            </div>
                          </div>
                          <div style={{ margin: 5, color: "green" }}>
                            {visibility ? (
                              !saldoPatrimonioSelect ? (
                                <CircularProgress color="secondary" />
                              ) : (
                                <h5>{valorDisponivelCripto}</h5>
                              )
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <Box
                        className="listaConfig col-sm-4 w-25"
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          borderRadius: 2,
                          boxShadow: 8,
                        }}
                      >

                        <List onClick={handleDrawerOpen}>

                          <ListItem disablePadding>
                              <CardActionArea onClick={() => navigate("/perfil")}>
                                <ListItemButton>
                                  <PersonIcon/>                                    
                                  <ListItemText
                                      primary="Perfil"
                                      className="col-sm-6"
                                      style={{ marginLeft: 15 }}
                                    />
                                </ListItemButton>
                              </CardActionArea>
                          </ListItem>

                          <ListItem disablePadding>
                            <CardActionArea onClick={() => navigate("/cripto")}>
                              <ListItemButton>
                                <AddIcon/>
                                    
                                  <ListItemText
                                    primary="Add Criptomoeda"
                                    className="col-sm-6"
                                    style={{ marginLeft: 15 }}
                                  />
                              </ListItemButton>
                            </CardActionArea>
                          </ListItem>

                        {configSwap ?(  
                        <ListItem disablePadding>
                          <CardActionArea onClick={() => navigate("/swap")}>
                            <ListItemButton>
                              <CurrencyBitcoinIcon />
                              <ListItemText
                                primary="Swap"
                                className="col-sm-6"
                                style={{ marginLeft: 15 }}
                              />
                            </ListItemButton>
                          </CardActionArea>
                        </ListItem>
                        ):(
                          <>
                          </>
                        )}

                        {mostrarBtnCashBack === true?(
                          <ListItem disablePadding>
                            <CardActionArea onClick={() => navigate("/cashBack")}>
                               <ListItemButton>
                                <CurrencyExchangeIcon/>
                                    
                                  <ListItemText
                                    primary="CashBack"
                                      className="col-sm-6"
                                    style={{ marginLeft: 15 }}
                                  
                                  />
                              </ListItemButton>
                            </CardActionArea>
                          </ListItem>)
                          :null 
                        }

                        {configAreaPix ? (
                          <ListItem disablePadding>
                            <CardActionArea onClick={() => navigate("/area-pix")}>
                              <ListItemButton>
                                <PixIcon />
                                <ListItemText
                                  primary="Area Pix"
                                  className="col-sm-6"
                                  style={{ marginLeft: 15 }}
                                />
                              </ListItemButton>
                            </CardActionArea>
                          </ListItem>                  
                        ):(
                          <>
                          </>
                        )}

                        <ListItem disablePadding>
                        <CardActionArea onClick={() => navigate("/stacking")}>
                          <ListItemButton>
                            <TrendingUpIcon  />

                            <ListItemText
                              primary="Stacking"
                              className="col-sm-6"
                              style={{ marginLeft: 15 }}
                            />
                          </ListItemButton>
                        </CardActionArea>
                        </ListItem>

                        </List>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </firstLoginWhiteLabelContext.Provider>
      </div>

    </>
  );
}
