import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



import {  toast,ToastContainer } from 'react-toastify';


//material

import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import partnerAPI from '../../../Services/partnerAPI';

import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";



export default function AdicionarQueimarToken() {
  const [asset,setAsset] = useState()
  const [mostrar, setMostrar] = useState(0);
  const [loading , setLoading] = useState(false)
  const localizar = useLocation();
  const [avatar , setAvatar]= useState ()
  const [contrato, setContrato] = useState(" ")
  const [valor,setValor] = useState(" ")
  const [nomeTextfield, setNomeTextfield] = useState("Quantidade")
  const [filtro, setFiltro] = useState(""); 
  const [drop , setDrop] = useState ("")

  useEffect(() => {
    setMostrar(localizar.state.type); 
    ContratoInteligente();
    WalletPessoaList();
  
  }, [localizar.state.type]);

  const [listCrypto, setlistCrypto] = useState({
    cdPessoa: 0,
    walletCryptosPessoa: [],
  });  

  const ContratoInteligente = async () => {
    setLoading(true)
    
    //console.log(JSON.stringify(sessionStorage.getItem("token")), 'sessionStorage.getItem WalletPessoaList');
    
    let contract = sessionStorage.getItem('contract');     
    let uid = sessionStorage.getItem('uid');  
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };
    
    await partnerAPI.get(`Partner/DadosToken_GetData?contract=${contract}&uid=${uid}`,{        
    },config).then(
        result => {
          const dataReturn = JSON.parse(result.data.data)
          console.log(result.data.data, "result.data.data DadosToken_GetData")
          setlistCrypto(result.data);
          setContrato(dataReturn.Name)
          setValor(dataReturn.Balance)
          setAsset(dataReturn.CdAsset)
          setLoading(false);
        }
    ).catch(error=>{
      console.log(error.response, 'walletPessoa WalletPessoaList')
    });
  }

  const WalletPessoaList = async () => {
    setLoading(true)
    
    console.log(JSON.stringify(sessionStorage.getItem("token")), 'sessionStorage.getItem WalletPessoaList');
    
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };
    
    await partnerAPI.post("/Partner/WalletPerson",{
      cryptoUserID: window.sessionStorage.getItem("uid")        
    },config).then(
        result => {
          setlistCrypto(result.data);          

          result.data.walletCryptosPessoa.forEach(x=>{

            setAvatar(x.urlImgCrypto)
          })
          setLoading(false)
    
        }
      ).catch(error=>{
      console.log(error.response, 'walletPessoa WalletPessoaList')
    });
  }

  const handleSelectChange =  (e) => {           
    //debugger    
    if(e.target.value === "porcentagem"){
      setNomeTextfield("%")
      setDrop(e.target.value)   
    }
    else if (e.target.value === "quantidade"){
      setNomeTextfield("Valor")
       setDrop(e.target.value) 
    }; 
  };

  const handleClickEnviarAdd = async (e) => {
    
    e.preventDefault()
    
    const jsonData= {
      cdAsset: asset,
      quantidade: '',
      porcentual: '',
      cryptoUserId: sessionStorage.getItem("uid")
    }
    if(nomeTextfield === "%"){
      jsonData.porcentual = filtro
      jsonData.quantidade = '0'       
    }
    else if (nomeTextfield === "Valor"){
      jsonData.quantidade = filtro
      jsonData.porcentual = '0'       
    };

    const json = JSON.stringify(jsonData)
    //console.log(jsonData)
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };
        
    const addTokens = await partnerAPI.post("/Partner/AddTokens",json,config)
    console.log(addTokens,"addToken");
    if(addTokens){
      toast.success('Cadastrado com sucesso');
      ContratoInteligente();
    }
    setFiltro('');
    setDrop('')
      
  };
 
  const handleClickEnviarQueimar = async (e) => {
    e.preventDefault()

    const jsonData= {
      cdAsset: asset,
      quantidade: '',
      porcentual: '',
      cryptoUserId: sessionStorage.getItem("uid")
    }
   if(nomeTextfield === "%"){
      jsonData.porcentual = filtro
      jsonData.quantidade = '0'       
    }
    else if (nomeTextfield === "Valor"){
      jsonData.quantidade = filtro
      jsonData.porcentual = '0'       
    };

    const json = JSON.stringify(jsonData)
    console.log(json)
    const config = {
      headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
      },
    };

    
    const burnTokens = await partnerAPI.post("/Partner/BurnTokens", json, config);
    console.log(burnTokens,"burnTokens");
    if(burnTokens){
      toast.success('Cadastrado com sucesso');
    }
    setFiltro('')
    setDrop('')
  };
  
  return (
    <div className="ms-4 pb-5 pt-3 mb-5 mt-3 ">
      {mostrar === 0 && (
        <>
          <div className="col-sm-9 ">
            <h5>Adicionar Token</h5>
          </div>
          <div className=" col-sm-12  " >
            <Card className="text-center col-sm-4 mb-5 mt-3 " >
              <CardContent className="col-sm-12 align-items-center" >
                {loading === true ?                
                <Stack sx={{ color: 'grey.500',alignItems: 'center', justifyContent: 'center'}} spacing={2} direction="row"style={{marginBottom:125, paddingTop:125}} >
                  <CircularProgress color="success" size={50} style={{marginBottom: 50}}  />        
                </Stack> :(
                  <div className="col-sm-12 row " >
                    <div  className="col-sm-12" >
                      <div className="d-flex align-items-center" >
                        <Avatar alt="Remy Sharp"   style={{borderBotton:0 ,marginRight:10 ,  width: 30, height: 30}}  src={avatar}  value={avatar} />
                        <TextField
                        style={{padding:7}}
                        className="col-sm-11"
                        id="standard-textarea"
                        label="Contrato Inteligente"
                        placeholder="Contrato Inteligente"
                        variant="standard"
                        type="text"
                        value={contrato}               
                        />                        
                      </div>
                    </div> 

                    <div>
                      <TextField
                        style={{paddingTop:10}}
                        className="col-sm-12"
                        id="standard-textarea"
                        label="Total de Tokens"
                        placeholder="Total de Tokens"
                        variant="standard"
                        type="number"
                        value={valor}                       
                      />
                    </div>
                    <div>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">
                          Quantidade / Porcentagem
                        </InputLabel>
                        <Select
                          style={{ paddingTop: 10 }}
                          className="col-sm-12 align-text-center"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Selecione o tipo"
                          onChange={handleSelectChange}
                          value={drop}
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="porcentagem">Porcentagem</MenuItem>
                          <MenuItem value="quantidade">Quantidade</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        style={{ paddingTop: 10 }}
                        className="col-sm-12"
                        id="standard-number"
                        label={nomeTextfield}
                        variant="standard"
                        type="number"
                        value={filtro}
                        onChange={(e)=>setFiltro(e.target.value)}                        
                        name="filtro"
                        required
                      />
                    </div>                                


                    <div className="mt-5  ">
                      <Stack direction="row-reverse">
                        <Button color="secondary" variant="contained" onClick={handleClickEnviarAdd}  >
                          <b>Adicionar</b>
                        </Button>
                      </Stack>
                    </div>

                    <div className="text-center  "></div>
                  </div>
                  
                )}
              </CardContent>
            </Card>
          </div>                
        </>                
      )}

      {mostrar === 1 && (
        <>
          <div className="col-sm-9 ">
            <h5>Queimar Token</h5>
          </div>
          <div className="col-sm-12  " >
            <Card className="text-center col-sm-4 mb-5 mt-3 " >
              <CardContent className="col-sm-12 align-items-center" >
                {loading === true ?                
                <Stack sx={{ color: 'grey.500',alignItems: 'center', justifyContent: 'center'}} spacing={2} direction="row"style={{marginBottom:125, paddingTop:125}} >
                  <CircularProgress color="success" size={50} style={{marginBottom: 50}}  />        
                </Stack> :(
                  <div className="col-sm-12 row " >
                    <div  className="col-sm-12" >
                      <div className="d-flex align-items-center" >
                        <Avatar alt="Remy Sharp"   style={{borderBotton:0 ,marginRight:10 ,  width: 30, height: 30}}  src={avatar}  value={avatar} />
                        <TextField
                        style={{padding:7}}
                        className="col-sm-11"
                        id="standard-textarea"
                        label="Contrato Inteligente"
                        placeholder="Contrato Inteligente"
                        variant="standard"
                        type="text"
                        value={contrato}               
                        />                        
                      </div>
                    </div> 

                    <div>
                      <TextField
                      style={{paddingTop:10}}
                        className="col-sm-12"
                        id="standard-textarea"
                        label="Total de Tokens"
                        placeholder="Total de Tokens"
                        variant="standard"
                        type="number"
                        value={valor}                 
                      />
                    </div>
                    <div>
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">
                          Quantidade / Porcentagem
                        </InputLabel>
                        <Select
                          style={{ paddingTop: 10 }}
                          className="col-sm-12 align-text-center"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Selecione o tipo"
                          onChange={handleSelectChange}
                          value={drop}
                        >
                          
                          <MenuItem value="porcentagem">Porcentagem</MenuItem>
                          <MenuItem value="quantidade">Quantidade</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        style={{ paddingTop: 10 }}
                        className="col-sm-12"
                        id="standard-number"
                        label={nomeTextfield}
                        variant="standard"
                        type="number"
                        value={filtro}
                        onChange={(e)=>setFiltro(e.target.value)}                        
                        name="filtro"
                        required
                      />
                    </div>                                


                    <div className="mt-5  ">
                      <Stack direction="row-reverse">
                        <Button color="secondary" variant="contained" onClick={handleClickEnviarQueimar}  >
                          <b>Queimar</b>
                        </Button>
                      </Stack>
                    </div>

                    <div className="text-center  "></div>
                  </div>
                  
                )}
              </CardContent>
            </Card>
          </div> 

        </>
      )}
      <ToastContainer />
    </div>
  );
}

