import {
  Card,
  CardContent,
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import authContext from "../../../Context/authContext";
import { useContext, useState } from "react";
import ModalDeposito from "./modalDeposito/modalDeposito";
import ModalSaque from "./modalSaque/modalSaque";
import PixIcon from '@mui/icons-material/Pix';
import SavingsIcon from '@mui/icons-material/Savings';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export default function AreaPix() {
  const { authenticated, setAuthenticated } = useContext(authContext);

  const [openSaque, setOpenSaque] = useState(false);
  const [openDeposito, setOpenDeposito] = useState(false);

  const handleOpenSaque = () => setOpenSaque(true);
  const handleOpenDeposito = () => setOpenDeposito(true);

  const handleCloseDeposito = () => setOpenDeposito(false);
  const handleCloseSaque = () => setOpenSaque(false);

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className="row col-sm-12"
      style={{ padding: 10, paddingBottom: 89, height: "100%" }}
    >
      <Card
        className="col-sm-4"
        style={{
          height: "414px",
          marginLeft: 10,
        }}
      >

        <CardContent style={{ paddingTop: 35,padding: 10, height: "80%" }}>
          <Box
            //height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="30px"
          >
            <Button
               style={{ backgroundColor: "#D4AA51"}}
              variant="contained"
              onClick={() => handleOpenDeposito()}
            >
              <SavingsIcon style={{width:30, height:30, marginRight:10}}/>
              Depositar
            </Button>
            <Button
               style={{ backgroundColor: "#D4AA51"}}
              variant="contained"
              onClick={() => handleOpenSaque()}
            >
              <AttachMoneyIcon style={{ width:30, height:30, marginRight:10}}/>
              Sacar
            </Button>
          </Box>
        </CardContent>
      </Card>
      <div className="col-sm-4">
        <p className="display-6" style={{ padding: 10 }}>
          <div className="row">
            <div className="col-sm-1">
              <PixIcon/>
            </div>
            <div className="col-sm-6">
              Área pix
            </div>
          </div>
        </p>
        <div className="text-left" style={{ padding: 10 }}>
          <p>Deposite ou saque em moeda nacional</p>
        </div>
      </div>
      {openDeposito && (
        <ModalDeposito open={openDeposito} handleClose={handleCloseDeposito} />
      )}
      {openSaque && (
        <ModalSaque open={openSaque} handleClose={handleCloseSaque} />
      )}
    </div>
  );
}
