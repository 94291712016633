import axios from 'axios';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, PUT, DELETE';

const authAPI = axios.create({
    //baseURL: 'https://apigatewaycipay.azure-api.net/corporate/',
    //baseURL: 'http://localhost:21018',
    baseURL: 'https://tokenizor-api-hml-691f9cdec60d.herokuapp.com/',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials':true
    },
    
})

export default authAPI; 

