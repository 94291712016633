import React, {useContext, useState} from 'react'
import { useNavigate ,BrowserRouter as Router} from 'react-router-dom';


//material
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import { Button, FormControlLabel } from '@mui/material';
import partnerAPI from '../../../Services/partnerAPI';


export default function TransacaoToken(props){

    const [boolActive, setBoolActive] = useState(false)    

    const label = { inputProps: { 'aria-label': 'Size switch demo' } };

    const handleClickSalvar = () => {
                
       
        var contract = sessionStorage.getItem('contract');          
        if(contract === null || contract === "" || contract === "null"){
            return window.alert('Acesso não permitido para fazer determinada ação.');
        }

        var data = {            
            'Active': boolActive,
            'ContractHash': contract
        };
        
        // agora fica para fazer a requisição para o back-end    
        const config = {
            headers: { Authorization: "Bearer " + window.sessionStorage.getItem("token")
            },
        };  

        partnerAPI.post("Partner/Transaction_Update", data, config)
        .then((res) => {
            console.log(res.data, 'SUCCESS');

            if(res.data.success === false){
                let text = "";
                for(let prop in res.data.erros){
                    text += `${prop} : ${res.data.erros[prop]}` + "\n";
                }                
                window.alert(text);                                                 
            }else{
                window.alert('Fluxo processado com sucesso!');                                
            }
        })            
        .catch(error => {
            window.alert('Ocorreu algum erro no processamento!');                
            console.log(error.response, 'ERRO');
        });

      };

    return (

        <div className='ms-3 my-5 card col-sm-3' >
            
            <div className='my-3' style={{margin: 10, marginBottom: 30}}>
                <h5>Transação</h5>
            </div>

            <div className='container' style={{marginBottom: 10}}>
                <div className='border border-2' style={{marginBottom: 15, borderRadius: 20, paddingBottom: 150}}>
                    <div className='col-sm-3 my-3'>
    
                        <div className='row' >                            
                            <div style={{margin: 20}}>
                                <FormControlLabel required control={<Switch checked={boolActive} onChange={e => setBoolActive(e.target.checked)} />} label="ATIVADO" />
                            </div>
                        </div>
                        
                    </div>                    
                </div>
                <div style={{position:"relative"}}>
                    <div style={{float:"right", margin:"10px"}}>                                                        
                        <Button variant="contained" onClick={handleClickSalvar}>Salvar</Button>                            
                    </div> 
                </div>
            </div>


        </div>
    );
}